import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const StyledDigikompetenzPodcastPage = styled.div`
  margin-block: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2rem;

  .item {
    display: flex;
  }

  .modal {
    width: 100%;
  }

  .modalItem {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .image {
    width: 100%;
    margin-right: 1rem;
  }

  img {
    width: 100%;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: bold;
  }

  .info {
    margin: 0.5rem 0;
    font-size: 0.8rem;
  }

  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .button {
    margin-top: 1rem;
    width: 100%;

    a {
      color: var(--primary);
    }
  }

  .modal {
    width: 100%;
  }

  .iframeContainer {
    position: relative;
    padding-bottom: 56.25% /* 16:9 */;
    height: 0;
    // margin: 5rem auto;

    // @media (min-width: 768px) {
    //   margin: 10rem auto;
    // }
  }

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const PodcastCard = data => {
  const {
    title,
    slug,
    cover,
    duration,
    release,
    season,
    youTube,
    anchor,
    audio,
    description,
    episode,
  } = data.data

  return (
    <>
      <div className="item">
        <div className="image">
          <Link to={`/digikompetenzpodcast/${slug}`}>
            <img srcSet={cover?.fluid?.srcSetWebp} alt={title} />
          </Link>
        </div>
        <div className="contentContainer">
          <div className="content">
            <div className="title">{title}</div>
            <div className="info">
              Episode {season === 1 ? episode : episode + 29} | Dauer:{" "}
              {duration === 1 ? "Minute" : "Minuten"} | Datum:{" "}
              {new Date(release).toLocaleDateString("de", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}{" "}
            </div>
            <div className="description">
              {documentToPlainTextString(description.json).substring(0, 150)}
            </div>
          </div>
          <div className="button">
            <Link to={`/digikompetenzpodcast/${slug}`}>
              <div>Zur Episode</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

const DigikompetenzPodcastPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPodcastEpisode(
        sort: { fields: release, order: DESC }
        filter: { node_locale: { eq: "de-DE" } }
      ) {
        edges {
          node {
            id
            anchor
            audio
            duration
            episode
            guests
            podcast
            season
            slug
            title
            description {
              json
            }
            release
            cover {
              fluid {
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `)

  const podcasts = data.allContentfulPodcastEpisode.edges
  return (
    <Layout>
      <SEO title="Digikompetenzpodcast" />
      <h1 className="container">Digikompetenzpodcast</h1>
      <StyledDigikompetenzPodcastPage className="container">
        {podcasts &&
          podcasts.map(podcast => (
            <PodcastCard data={podcast.node} key={podcast.node.id} />
          ))}
      </StyledDigikompetenzPodcastPage>
    </Layout>
  )
}

export default DigikompetenzPodcastPage
